import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import {Button, Form,FormItem,Input,Loading,Divider,Row,Col,Result,
  Checkbox,CheckboxGroup,Upload , Radio , RadioGroup,Message,Collapse,CollapseItem,Dialog,Alert,DatePicker,Select
} from 'element-ui';

import Print from 'vue-print-nb' //引入

Vue.use(Print);


//跳转页面，新页面定位到顶部
router.afterEach(()=>{
  document.documentElement.scrollTop=0;
})

import { Popup,Field,DatetimePicker ,Uploader} from 'vant'

Vue.config.productionTip = false
Vue.use(Button).use(Form).use(FormItem).use(Input).use(Checkbox).use(CheckboxGroup).use(Upload)
    .use(Radio).use(RadioGroup).use(Loading).use(Divider).use(Row).use(Col).use(Result).use(Collapse).use(CollapseItem)
    .use(Dialog).use(Alert).use(DatePicker).use(Select) .use(Popup).use(Field).use(DatetimePicker ).use(Uploader)
Vue.use(Antd)
Vue.prototype.$message = Message
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
