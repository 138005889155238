import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isSelect_button:true,
  },
  mutations: {
    submit_isSelect_button(state,bol){
      state.isSelect_button=bol;
    }
  },
  actions: {
  },
  modules: {
  }
})
