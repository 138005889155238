import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'esm',
    component: () =>import('../views/Home')
  },
  {
    path: '/esmolol/m',
    name: 'esm',
    component: () =>import('../views/MaZui.vue')
  },
  {
    path: '/esmolol/y',
    name: 'esy',
    component: () => import('../views/YongYao.vue')
  },
  {
    path: '/esmolol/b',
    name: 'esb',
    component: () => import('../views/Bing')
  },
  {
    path:'/admin/b',
    name:'ab',
    component:()=>import('../views/admin/Bing')
  },
    //比伐芦定在冠心病行PCI治疗患者中应用的真实世界研究 病例问卷
  // {
  //   path:'/chd/info',
  //   name:'chdInfo',
  //   component:()=>import('../views/CHD/CHD_info.vue')
  // },
  {
    path:'/chd/survey',
    name:'chdSurvey',
    component:()=>import('../views/CHD/CHD_survey.vue')
  },
  {
    path:'/chdCase',
    name:'chdSurvey',
    component:()=>import('../views/CHD/CHD_case.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router
